import Chart from 'chart.js/auto';

class ChartContainer extends HTMLElement {

  constructor() {
    super();

    this.canvas = document.createElement('canvas');
  }

  connectedCallback() {
    this.canvas.width  = this.getAttribute('width');
    this.canvas.height = this.getAttribute('height');
    this.ctx = this.canvas.getContext('2d');
    this.appendChild(this.canvas);
    window.requestAnimationFrame(this.setup.bind(this));
  }

  setup() {
    if (!this.datalist) return false;

    const data = {
      labels: Array.prototype.map.call(this.datalist.childNodes, e => e.textContent),
      datasets: [{
        data: Array.prototype.map.call(this.datalist.childNodes, e => e.value),
        backgroundColor: 'rgba(106, 10, 0, 0.7)',
        hoverBackgroundColor: 'rgba(253, 190, 25, 1)',
        borderWidth: 1
      }]
    };

    this.chart = new Chart(this.ctx, {
      type: this.getAttribute('chart-type'),
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: false,
          title: {
            font: {
              size: 16,
              weight: 500,
              family: "'Noto Serif', serif"
            },
            color: '#c3bdac',
            display: true,
            position: 'bottom',
            text: this.getAttribute('title')
          }
        },
      }
    });
  }

  get datalist() {
    return this.querySelector('datalist');
  }
}

customElements.define('chart-container', ChartContainer);
