import SlimSelect from 'slim-select'

(function() {
  'use strict';

  function adder(value) {
    return value;
  }

  function init(event) {
    document.querySelectorAll('select').forEach(elem => {
      let opts = {select: elem};
      if (elem.dataset.allowAdd) {
        opts['addable'] = adder;
      }
      new SlimSelect(opts)
    });
  }

  document.addEventListener('DOMContentLoaded', init);
  document.addEventListener('turbolinks:load', init);
} ());
