import List from 'list.js';


class SortableList extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    if (this.querySelectorAll('.list').size === 0) {
      console.log('sortable-list: You must specify an element (tbody?) with class "list"', this);
      return false;
    }
    if (this.querySelectorAll('[data-sort]').size === 0) {
      console.log('sortable-list: You must specify the "data-sort" attribute on at least one header element', this);
      return '';
    }

    window.requestAnimationFrame(this.setup.bind(this));
  }

  setup() {
    const hdr = this.querySelectorAll('[data-sort]');
    const valueNames = Array.prototype.map.call(hdr, hdrElem => {
      hdrElem.classList.add('sort');

      if (hdrElem.dataset.sortAttr) {
        return { name: hdrElem.dataset.sort, attr: hdrElem.dataset.sortAttr };
      } else {
        return hdrElem.dataset.sort;
      }
    });

    this.list = new List(this, { valueNames: valueNames });
  }
}

customElements.define('sortable-list', SortableList);
