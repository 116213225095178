import List from 'list.js';


class FilterableObservationsList extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    window.requestAnimationFrame(this.setup.bind(this));
  }

  setup() {
    const hdr = this.querySelectorAll('[data-sort]');
    const valueNames = Array.prototype.map.call(hdr, btn => {
      btn.classList.add('sort');
      return btn.dataset.sort
    });

    this.list = new List(this, { valueNames: valueNames });
  }
}

customElements.define('filterable-observations-list', FilterableObservationsList);
