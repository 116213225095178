/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Trix from "trix"
import "@rails/actiontext"
import 'bootstrap';
// import 'vue/dist/vue.esm.js';
import '../src/chart-container.js';
import '../src/slimselect.js';
// import '../src/draggables.js';
import '../src/filterable_observations_list.js';
import '../src/sortable-table.js';
// import '../src/vue-droppable-basket.js';

Trix.config.blockAttributes.default.tagName = 'p';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

function ajaxUpdateAttribute(url, attr, val, successCallback) {
  var oReq = new XMLHttpRequest();
  oReq.open("post", url);
  oReq.onload = successCallback;
  oReq.withCredentials = true;
  console.log(url)
  var fd = new FormData();
  fd.append('_method', 'put');
  fd.append(attr, val);
  fd.append(document.querySelector('meta[name="csrf-param"').getAttribute('content'), document.querySelector('meta[name="csrf-token"').getAttribute('content'));
  oReq.setRequestHeader('Accept', 'application/json');
  oReq.send(fd);
}

function AJAXSubmit(oFormElement, successCallback) {
  if (!oFormElement.action) { return; }
  var oReq = new XMLHttpRequest();
  oReq.onload = successCallback;
  if (oFormElement.method.toLowerCase() === "post") {
    oReq.open("post", oFormElement.action);
    oReq.send(new FormData(oFormElement));
  } else {
    var oField, sFieldType, nFile, sSearch = "";
    for (var nItem = 0; nItem < oFormElement.elements.length; nItem++) {
      oField = oFormElement.elements[nItem];
      if (!oField.hasAttribute("name")) { continue; }
      sFieldType = oField.nodeName.toUpperCase() === "INPUT" ?
          oField.getAttribute("type").toUpperCase() : "TEXT";
      if (sFieldType === "FILE") {
        for (nFile = 0; nFile < oField.files.length;
            sSearch += "&" + escape(oField.name) + "=" + escape(oField.files[nFile++].name));
      } else if ((sFieldType !== "RADIO" && sFieldType !== "CHECKBOX") || oField.checked) {
        sSearch += "&" + escape(oField.name) + "=" + escape(oField.value);
      }
    }
    oReq.open("get", oFormElement.action.replace(/(?:\?.*)?$/, sSearch.replace(/^&/, "?")), true);
    oReq.send(null);
  }
}
